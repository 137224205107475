<template lang="html">
<GradientBackLayout
  class="HeatMapActivity"
  :title="$filters.translate($filters.formatActivityType(communityCurrentActivity.type)).toUpperCase()"
  :shadowText="$filters.translate('activity').toUpperCase()">
  <BoxShadow class="HeatMapActivity--Box">
    <p class="Activity--HeatMapInfo">
      <i class="fas fa-info-circle"></i>
      {{$t('activity_heatmap_info')}}
      </p>
    <HeatMap v-if="communityCurrentActivity.heatmapPointDefinitions" ref="HeatMap" :images="communityCurrentActivity.heatMapImages" :heatmapPointDefinitions="communityCurrentActivity.heatmapPointDefinitions" containerClassName="HeatMapActivity" v-on:send-answers="disableSubmitButton()"/>
    <CommunityButton style="margin-top:10px;" v-on:click="sendHeatMapResponse()" :disabled="disableSubmit">{{$t('activity_heatmap_send_points')}}</CommunityButton>
    <!-- Remove comment for testing -->
    <!-- <HeatMapResults v-for="(img, index) in communityCurrentActivity.heatMapImages" v-if="communityCurrentActivity.heatmapPointDefinitions" :answersNum="communityCurrentActivity.membersNum" :image="img" :heatMapAnswers="getAnswersByImageIndex(index)" :heatmapPointDefinitions="getPinsByImageIndex(index)"/> -->
  </BoxShadow>
</GradientBackLayout>
</template>

<script>
import Swal from 'sweetalert2'
import { mapGetters } from 'vuex';
import { map, flatMap } from 'lodash';
import HeatMap from '~/components/HeatMap';
import CommunityButton from '@/components/CommunityButton.vue'
import GradientBackLayout from '@/layouts/GradientBackLayout'
import BoxShadow from '@/components/BoxShadow.vue'
import store from '@/store';
import {
  COMMUNITY_FETCH_ACTIVITIES,
  COMMUNITY_FETCH_ACTIVITY_COMMENTS,
  COMMUNITY_FETCH_COMMENTS_SEARCH,
  COMMUNITY_ADD_ACTIVITY_QUESTIONS_RESPONSE,
  COMMUNITY_FETCH_ACTIVITY,
} from '@/store/community/activities.module';
import HeatMapResults from '~/components/HeatMap/HeatMapResults'
export default {
  name: 'HeatMapExecution',
  computed:{
    ...mapGetters([
      'communityCurrentActivity',
      'communityCurrentActivityId',
    ]),
  },
  components:{
    HeatMap,
    CommunityButton,
    GradientBackLayout,
    BoxShadow,
    HeatMapResults
  },
  data(){
    return{
      disableSubmit: true,
      heatMapAnswers:{
        hmvaluesByType:{
          RED:[],
          GREEN:[],
        },
        hmvaluesByPoint:{}
      }
    }
  },
  methods:{
    getAnswersByImageIndex(index){
      return this.heatMapAnswers
    },
    getPinsByImageIndex(index){
      return _.filter(this.communityCurrentActivity.heatmapPointDefinitions, function (p) { return p.imageIndex == index });
    },
    disableSubmitButton(){
      let disable = false
      if(this.$refs.HeatMap == undefined){
        this.disableSubmit = true
        return
      }
      for(let pin of this.$refs.HeatMap.pins){
        if(!pin.added) disable = true
      }
      this.disableSubmit = disable
      this.setPoints()
    },
    setPoints(){
      this.heatMapAnswers.hmvaluesByType = {
        RED:[],
        GREEN:[],
      }
      for(let pin of this.$refs.HeatMap.pins){
        this.heatMapAnswers.hmvaluesByType[pin.type].push(pin)
      }
    },
    async sendHeatMapResponse(){
      let answers = this.$refs.HeatMap.pins
      let data = {
        questionId:this.communityCurrentActivity.communityQuestions[0].identifier,
        hmvalues:answers.map(function(pin) {
          let answer = {
            identifier: pin.identifier,
            type: pin.type,
            x: pin.x,
            y: pin.y,
            comment: pin.comment,
            imageIndex: pin.imageIndex
          }
          return answer
        })
      }
      await store.dispatch(COMMUNITY_ADD_ACTIVITY_QUESTIONS_RESPONSE, {
        activityId: this.communityCurrentActivityId,
        data: [data],
      })
      .then(async (data) => {
        if(data == "" || data == undefined || data == null || data.participatedMessage){
          Swal.fire(this.$t('activity_answer_saved'),this.$t('activity_thanks_participated'))
            await store.dispatch(COMMUNITY_FETCH_ACTIVITIES, { priority: 'myActivities' });

          this.$router.push({ name: 'activity.info', params: { id: this.communityCurrentActivityId } })
        }
      });
    },
  },
}
</script>
<style scoped lang="scss">
.HeatMapActivity{
  width: 100%;
  &--Box{
    flex-direction: column;
    align-items: flex-end;
  }
}
// &--HeatMapInfo{
//   margin-bottom: 10px;
//   color: #004085;
//   background-color: #cce5ff;
//   border:1px solid #b8daff;
//   padding: 5px;
//   i{
//     color: #007bff;
//   }
// }
</style>
